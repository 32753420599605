import { lazy } from "react";

const routes = [
  {
    path: "dashboard",
    component: lazy(() => import("../screens/Dashboard")),
    exact: true,
  },
  {
    path: "",
    component: lazy(() => import("../screens/Dashboard")),
    exact: true,
  },
  {
    path: "master/panchayet-list",
    component: lazy(() => import("../screens/masters/PanchayetMaster")),
    exact: true,
  },
  {
    path: "master/village-list",
    component: lazy(() => import("../screens/masters/VillageMaster")),
    exact: true,
  },
  {
    path: "master/household-list",
    component: lazy(() => import("../screens/masters/HouseholdMaster")),
    exact: true,
  },
  {
    path: "family/add-member",
    component: lazy(() => import("../screens/family/AddMember")),
    exact: true,
  },
  {
    path: "family/edit-member/:memberId?",
    component: lazy(() => import("../screens/family/AddMember")),
    exact: true,
  },
  {
    path: "family/members/:memberId?/:householdId?",
    component: lazy(() => import("../screens/family/FamilyMembers")),
    exact: true,
  },
  {
    path: "family/view-member/:memberId",
    component: lazy(() => import("../screens/family/ViewMember")),
    exact: true,
  },
  {
    path: "user/add",
    component: lazy(() => import("../screens/loginuser/AddUser")),
    exact: true,
  },
  {
    path: "user/all",
    component: lazy(() => import("../screens/loginuser/AllUser")),
    exact: true,
  },
  {
    path: "medicine/category/add",
    component: lazy(() => import("../screens/medicine/AddMedicineCategory")),
    exact: true,
  },
  {
    path: "medicine/categories",
    component: lazy(() => import("../screens/medicine/MedicineCategories")),
    exact: true,
  },
  {
    path: "medicine/add",
    component: lazy(() => import("../screens/medicine/AddMedicine")),
    exact: true,
  },
  {
    path: "medicines",
    component: lazy(() => import("../screens/medicine/Medicines")),
    exact: true,
  },
  {
    path: "medicines/upcoming-expiry",
    component: lazy(() =>
      import("../screens/medicine/UpcomingExpiryMedicines")
    ),
    exact: true,
  },
  {
    path: "medicine-stock/add",
    component: lazy(() => import("../screens/medicine/AddNewStock")),
    exact: true,
  },
  {
    path: "medicine-stock/history",
    component: lazy(() => import("../screens/medicine/MedicineStockHistory")),
    exact: true,
  },
  {
    path: "patient/medical-history",
    component: lazy(() => import("../screens/patients/PatientTimeLine")),
    exact: true,
  },
  {
    path: "master/household/survey-list/:uniqueCode/:householdId",
    component: lazy(() => import("../screens/masters/HouseholdSurveyData")),
    exact: false,
  },
  {
    path: "master/villlage/survey-list/:villageName/:villageId",
    component: lazy(() => import("../screens/masters/VillageSurveyData")),
    exact: false,
  },
  // define routes to manage assets
  {
    path: "asset/add-category/:categoryId?",
    component: lazy(() => import("../screens/assets/AddAssetCategory")),
    exact: true,
  },
  {
    path: "asset/categories",
    component: lazy(() => import("../screens/assets/AssetCategoryList")),
    exact: true,
  },
  {
    path: "assets/:categoryId?",
    component: lazy(() => import("../screens/assets/AssetList")),
    exact: true,
  },
  {
    path: "asset/add-asset/:assetId?",
    component: lazy(() => import("../screens/assets/AddAsset")),
    exact: true,
  },
  {
    path: "asset/billing",
    component: lazy(() => import("../screens/assets/AssetBilling")),
    exact: true,
  },
  {
    path: "asset/history/:assetId?",
    component: lazy(() => import("../screens/assets/AssetsHistory")),
    exact: false,
  },
  {
    path: "content/privacy-policy",
    component: lazy(() => import("../screens/content/PrivacyPolicyContent")),
    exact: false,
  },
];

export default routes;
