export const JWT_AUTH_TOKEN = "__JWT_AUTH_TOKEN__";
export const USER_ID = `__USER_ID__`;
export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "error";
export const APP = "APP";
export const WEB = "WEB";

// http request method
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

// api sub routes
export const LOGIN_ROUTE = "user/login";
export const LOGIN_USER_DETAILS_ROUTE = "user/details";
export const MASTER_ROUTE = "master/";
export const MEMBERS_DATA_ROUTE = "family/members";
export const MEMBERS_ADD_UPDATE = "family/member/add";
export const MEMBERS_MODIFICATION = "family/member/update";
export const MEMBER_PHOTO_UPDATE = "family/member/addphoto";
export const DASHBOARD_DATA = "user/dashboard";
export const REGISTER_NEW_LOGIN_USER = `user/register`;
export const GET_ALL_LOGIN_USERS = `user/all`;
export const RESET_PASSWORD_LOGIN_USERS = `user/resetpassword`;
export const ADD_MEDICINE_CATEGORY_ROUTE = `medicine/category/add`;
export const UPDATE_MEDICINE_CATEGORY_ROUTE = `medicine/category/update`;
export const MEDICINE_CATEGORY_LIST_ROUTE = `medicine/categories`;
export const MEDICINE_ADD_ROUTE = `medicine/add`;
export const MEDICINE_UPDATE_ROUTE = `medicine/update`;
export const MEDICINE_LIST_ROUTE = `medicines`;
export const UPCOMING_EXPIRY_MEDICINE_LIST_ROUTE = `medicines/upcoming-expiry`;
export const MEDICINE_STOCK_MANAGEMENT = `stock/manage`;
export const MEDICINE_STOCK_HISTORY = `stock/history`;
export const PRESCRIPTION_DETAILS_ROUTE = `prescriptions`;
export const HOUSEHOLD_ROUTE = `household`;
export const HOUSEHOLD_SURVEY_DATA_ADD = `add-household-survey`;
export const GET_HOUSEHOLD_SURVEY_DATA = `household-survey-data`;
export const VILLAGE_SURVEY_DATA_ADD = `add-village-survey`;
export const GET_VILLAGE_SURVEY_DATA = `village-survey-data`;
export const ADD_ASSETS_CATEGORY = `asset/add-category`;
export const GET_ASSETS_CATEGORY = `asset/get-categories`;
export const ADD_ASSETS = `asset/add-assets`;
export const GET_ASSETS = `asset/get-assets`;
export const GET_ASSET_AVAILABILITY = `asset`;
export const ASSET_BILLING_ROUTE = `asset/billing`;
export const ASSET_HISTORY_ROUTE = `asset-transactions`;
export const GET_AN_ASSET_CATEGORY_ROUTE = `asset/category/`;
export const GET_ASSET_DETAILS_WITH_ID = `asset-details`;
export const UPDATE_ASSET_DETAILS_WITH_ID = `update-asset`;
export const UPCOMING_EXPIRY_MEDICINE_API_ROUTE = `upcoming-expire-medicine`;

// validation message
export const EMPTY_EMAIL_ALERT = "Please enter your email";
export const INVALID_EMAIL_ALERT = "Please enter a valid email";
export const EMPTY_PASSWORD_ALERT = "Please enter your password";

export const ERROR_API_404_ALERT = "Server route not found";
export const ERROR_API_500_ALERT = "Bad response from server";

// action type constants
export const REQUEST_USER_LOGIN = "REQUEST_USER_LOGIN";
export const SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN";
export const FAILED_USER_LOGIN = "FAILED_USER_LOGIN";

export const REQUEST_LOGIN_USER_DETAILS = "REQUEST_LOGIN_USER_DETAILS";
export const SUCCESS_LOGIN_USER_DETAILS = "SUCCESS_LOGIN_USER_DETAILS";
export const FAILED_LOGIN_USER_DETAILS = "FAILED_LOGIN_USER_DETAILS";

export const REQUEST_MASTER_LIST = "REQUEST_MASTER_LIST";
export const SUCCESS_MASTER_LIST = "SUCCESS_MASTER_LIST";
export const FAILED_MASTER_LIST = "FAILED_MASTER_LIST";

export const REQUEST_FAMILY_MEMBERS = "REQUEST_FAMILY_MEMBERS";
export const SUCCESS_FAMILY_MEMBERS = "SUCCESS_FAMILY_MEMBERS";
export const FAILED_FAMILY_MEMBERS = "FAILED_FAMILY_MEMBERS";

export const REQUEST_ADD_FAMILY_MEMBERS = "REQUEST_ADD_FAMILY_MEMBERS";
export const SUCCESS_ADD_FAMILY_MEMBERS = "SUCCESS_ADD_FAMILY_MEMBERS";
export const FAILED_ADD_FAMILY_MEMBERS = "FAILED_ADD_FAMILY_MEMBERS";

export const REQUEST_GET_DASHBOARD_DATA = "REQUEST_GET_DASHBOARD_DATA";
export const SUCCESS_GET_DASHBOARD_DATA = "SUCCESS_GET_DASHBOARD_DATA";
export const FAILED_GET_DASHBOARD_DATA = "FAILED_GET_DASHBOARD_DATA";

export const REQUEST_TO_UPDATE_MEMBER_PHOTO = "REQUEST_TO_UPDATE_MEMBER_PHOTO";
export const SUCCESS_TO_UPDATE_MEMBER_PHOTO = "SUCCESS_TO_UPDATE_MEMBER_PHOTO";
export const FAILED_TO_UPDATE_MEMBER_PHOTO = "FAILED_TO_UPDATE_MEMBER_PHOTO";

export const REQUEST_TO_CHANGE_MEMEBER_REDUCER_DATA =
  "REQUEST_TO_CHANGE_MEMEBER_REDUCER_DATA";

export const REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE =
  "REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE";
export const SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE =
  "SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE";
export const ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE =
  "ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE";

export const REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA =
  "REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA";
export const SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA =
  "SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA";
export const FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA =
  "FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA";

export const REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA =
  "REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA";
export const SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA =
  "SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA";
export const FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA =
  "FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA";

export const REQUEST_TO_ADD_VILLAGE_SURVEY_DATA =
  "REQUEST_TO_ADD_VILLAGE_SURVEY_DATA";
export const SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA =
  "SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA";
export const FAILED_TO_ADD_VILLAGE_SURVEY_DATA =
  "FAILED_TO_ADD_VILLAGE_SURVEY_DATA";

export const REQUEST_TO_GET_VILLAGE_SURVEY_DATA =
  "REQUEST_TO_GET_VILLAGE_SURVEY_DATA";
export const SUCCESS_TO_GET_VILLAGE_SURVEY_DATA =
  "SUCCESS_TO_GET_VILLAGE_SURVEY_DATA";
export const FAILED_TO_GET_VILLAGE_SURVEY_DATA =
  "FAILED_TO_GET_VILLAGE_SURVEY_DATA";

export const REQUSET_TO_ADD_ASSETS_CATEGORY = "REQUSET_TO_ADD_ASSETS_CATEGORY";
export const SUCCESS_TO_ADD_ASSETS_CATEGORY = "SUCCESS_TO_ADD_ASSETS_CATEGORY";
export const FAILED_TO_ADD_ASSETS_CATEGORY = "FAILED_TO_ADD_ASSETS_CATEGORY";

export const REQUSET_TO_GET_ASSETS_CATEGORY = "REQUSET_TO_GET_ASSETS_CATEGORY";
export const SUCCESS_TO_GET_ASSETS_CATEGORY = "SUCCESS_TO_GET_ASSETS_CATEGORY";
export const FAILED_TO_GET_ASSETS_CATEGORY = "FAILED_TO_GET_ASSETS_CATEGORY";

export const REQUSET_TO_ADD_ASSETS = "REQUSET_TO_ADD_ASSETS";
export const SUCCESS_TO_ADD_ASSETS = "SUCCESS_TO_ADD_ASSETS";
export const FAILED_TO_ADD_ASSETS = "FAILED_TO_ADD_ASSETS";

export const REQUSET_TO_GET_ASSETS = "REQUSET_TO_GET_ASSETS";
export const SUCCESS_TO_GET_ASSETS = "SUCCESS_TO_GET_ASSETS";
export const FAILED_TO_GET_ASSETS = "FAILED_TO_GET_ASSETS";

export const RESET_REDUCER_STATE = "RESET_REDUCER_STATE";

export const REQUEST_TO_CHECK_ASSET_AVAILABILITY =
  "REQUEST_TO_CHECK_ASSET_AVAILABILITY";
export const SUCCESS_TO_CHECK_ASSET_AVAILABILITY =
  "SUCCESS_TO_CHECK_ASSET_AVAILABILITY";
export const FAILED_TO_CHECK_ASSET_AVAILABILITY =
  "FAILED_TO_CHECK_ASSET_AVAILABILITY";

export const REQUEST_FOR_ASSET_BILLING = "REQUEST_FOR_ASSET_BILLING";
export const SUCCESS_FOR_ASSET_BILLING = "SUCCESS_FOR_ASSET_BILLING";
export const FAILED_FOR_ASSET_BILLING = "FAILED_FOR_ASSET_BILLING";

export const REQUEST_GET_ASSET_TRANSACTION_HISTORY =
  "REQUEST_GET_ASSET_TRANSACTION_HISTORY";
export const SUCCESS_GET_ASSET_TRANSACTION_HISTORY =
  "SUCCESS_GET_ASSET_TRANSACTION_HISTORY";
export const FAILED_GET_ASSET_TRANSACTION_HISTORY =
  "FAILED_GET_ASSET_TRANSACTION_HISTORY";

export const REQUSET_TO_GET_ASSETS_CATEGORY_WITH_ID =
  "REQUSET_TO_GET_ASSETS_CATEGORY_WITH_ID";
export const SUCCESS_TO_GET_ASSETS_CATEGORY_WITH_ID =
  "SUCCESS_TO_GET_ASSETS_CATEGORY_WITH_ID";
export const FAILED_TO_GET_ASSETS_CATEGORY_WITH_ID =
  "FAILED_TO_GET_ASSETS_CATEGORY_WITH_ID";

export const REQUEST_TO_GET_ASSET_DETAILS_WITH_ID =
  "REQUEST_TO_GET_ASSET_DETAILS_WITH_ID";
export const SUCCESS_TO_GET_ASSET_DETAILS_WITH_ID =
  "SUCCESS_TO_GET_ASSET_DETAILS_WITH_ID";
export const FAILED_TO_GET_ASSET_DETAILS_WITH_ID =
  "FAILED_TO_GET_ASSET_DETAILS_WITH_ID";

export const REQUEST_TO_UPDATE_AN_ASSET = "REQUEST_TO_UPDATE_AN_ASSET";
export const SUCCESS_TO_UPDATE_AN_ASSET = "SUCCESS_TO_UPDATE_AN_ASSET";
export const FAILED_TO_UPDATE_AN_ASSET = "FAILED_TO_UPDATE_AN_ASSET";

// route json
export const sidebar_items = [
  {
    menuId: 1,
    menuName: "Dashboard",
    menuNavItemId: "parent_menu_item_1",
    menuId: "dashboard_unique_id",
    aTagMenuId: "dashboard_unique_id_a",
    menuIcon: "mdi mdi-home menu-icon",
    menuRoute: "/dashboard",
    submenuItems: [],
  },
  {
    menuId: 2,
    menuName: "Manage Population",
    menuNavItemId: "parent_menu_item_2",
    menuId: "manage_population_unique_id",
    aTagMenuId: "manage_population_unique_id_a",
    menuIcon: "mdi mdi-account-multiple menu-icon",
    submenuItems: [
      {
        submenuId: 1,
        submenuName: "Panchayets",
        submenuRoute: "/master/panchayet-list",
        submenuNavItemId: "child_menu_item_1",
      },
      {
        submenuId: 2,
        submenuName: "Villages",
        submenuRoute: "/master/village-list",
        submenuNavItemId: "child_menu_item_2",
      },
      {
        submenuId: 3,
        submenuName: "Households",
        submenuRoute: "/master/household-list",
        submenuNavItemId: "child_menu_item_3",
      },
      {
        submenuId: 5,
        submenuName: "Add Member",
        submenuRoute: "/family/add-member",
        submenuNavItemId: "child_menu_item_4",
      },
      {
        submenuId: 6,
        submenuName: "All Members",
        submenuRoute: "/family/members",
        submenuNavItemId: "child_menu_item_5",
      },
      {
        submenuId: 7,
        submenuName: "Add User",
        submenuRoute: "/user/add",
        submenuNavItemId: "child_menu_item_6",
      },
      {
        submenuId: 8,
        submenuName: "Login Users",
        submenuRoute: "/user/all",
        submenuNavItemId: "child_menu_item_7",
      },
    ],
  },
  {
    menuId: 3,
    menuName: "Medicine Inventory",
    menuNavItemId: "parent_menu_item_3",
    menuId: "medicine_inventory_unique_id",
    aTagMenuId: "medicine_inventory_unique_id_a",
    menuIcon: "mdi mdi-ambulance menu-icon",
    submenuItems: [
      {
        submenuId: 1,
        submenuName: "Categories",
        submenuRoute: "/medicine/categories",
        submenuNavItemId: "child_menu_item_8",
      },
      {
        submenuId: 2,
        submenuName: "Add Categories",
        submenuRoute: "/medicine/category/add",
        submenuNavItemId: "child_menu_item_9",
      },
      {
        submenuId: 3,
        submenuName: "Medicines",
        submenuRoute: "/medicines",
        submenuNavItemId: "child_menu_item_10",
      },
      {
        submenuId: 4,
        submenuName: "Add Medicines",
        submenuRoute: "/medicine/add",
        submenuNavItemId: "child_menu_item_11",
      },
      {
        submenuId: 5,
        submenuName: "Add Stock",
        submenuRoute: "/medicine-stock/add",
        submenuNavItemId: "child_menu_item_12",
      },
      {
        submenuId: 6,
        submenuName: "Stock History",
        submenuRoute: "/medicine-stock/history",
        submenuNavItemId: "child_menu_item_13",
      },
      {
        submenuId: 7,
        submenuName: "Upcoming Expiry",
        submenuRoute: "/medicines/upcoming-expiry",
        submenuNavItemId: "child_menu_item_14",
      },
    ],
  },
  {
    menuId: 4,
    menuName: "Assets Management",
    menuNavItemId: "assets_management_menu_item_4",
    menuId: "assets_management_unique_id",
    aTagMenuId: "assets_management_unique_id_a",
    menuIcon: "mdi mdi-content-paste menu-icon",
    submenuItems: [
      {
        submenuId: 1,
        submenuName: "Assets",
        submenuRoute: "/assets",
        submenuNavItemId: "assets_management_child_menu_item_1",
      },
      {
        submenuId: 2,
        submenuName: "Add Assets",
        submenuRoute: "/asset/add-asset",
        submenuNavItemId: "assets_management_child_menu_item_2",
      },
      {
        submenuId: 3,
        submenuName: "Assets History",
        submenuRoute: "/asset/history",
        submenuNavItemId: "assets_management_child_menu_item_3",
      },
      {
        submenuId: 4,
        submenuName: "Assets Category",
        submenuRoute: "/asset/categories",
        submenuNavItemId: "assets_management_child_menu_item_4",
      },
      {
        submenuId: 5,
        submenuName: "Add Category",
        submenuRoute: "/asset/add-category",
        submenuNavItemId: "assets_management_child_menu_item_5",
      },
      {
        submenuId: 6,
        submenuName: "Asset Billing",
        submenuRoute: "/asset/billing",
        submenuNavItemId: "assets_management_child_menu_item_6",
      },
    ],
  },
  // {
  //   menuId: 5,
  //   menuName: "Content Management",
  //   menuNavItemId: "content_management_menu_item_5",
  //   menuId: "content_management_unique_id",
  //   aTagMenuId: "content_management_unique_id_a",
  //   menuIcon: "mdi mdi-file-document menu-icon",
  //   submenuItems: [
  //     {
  //       submenuId: 1,
  //       submenuName: "Privacy Policy",
  //       submenuRoute: "/content/privacy-policy",
  //       submenuNavItemId: "content_management_child_menu_item_1",
  //     },
  //   ],
  // },
];
