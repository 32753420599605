import React from "react";
import "../../assets/css/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 01.07.2024
      </p>
      <p>
        Your privacy is important to us. This Privacy Policy explains how
        Goodhealth ("we", "us", "our") collects, uses, and shares personal
        information for a user from our application, and other services
        (Medical).
      </p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <h3>a. Personal Information</h3>
      <p>
        <strong>Email Address:</strong> When you sign up for our services or
        contact us, we may collect your email address.
      </p>
      <p>
        <strong>Other Identifiable Information:</strong> Depending on the
        features of our Services, we may also collect other personal details
        like your name, phone number, or address.
      </p>
      <h3>b. Non-Personal Information</h3>
      <p>
        <strong>Device Information:</strong> We may collect information about
        the device you use to access our services (e.g., device model, operating
        system, etc.).
      </p>
      <p>
        <strong>Usage Data:</strong> We collect data about how you interact with
        our website and app, including pages viewed, time spent, and other
        related statistics.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for the following purposes:</p>
      <p>
        <strong>To Provide and Improve Our Services:</strong> We use your
        personal information to create and manage your account, offer support,
        and improve the functionality of our app or website.
      </p>
      <p>
        <strong>Communication:</strong> Your email address may be used to send
        you important updates, newsletters, or respond to your inquiries.
      </p>
      <p>
        <strong>Compliance:</strong> We may use your data to ensure we comply
        with legal obligations and to protect the safety of our users.
      </p>

      <h2>3. Sharing of Information</h2>
      <p>
        We do not share or sell your personal information to third parties,
        except in the following cases:
      </p>
      <p>
        <strong>Service Providers:</strong> We may share your data with trusted
        third-party service providers who help us manage and deliver our
        services (e.g., hosting providers, analytics providers).
      </p>
      <p>
        <strong>Legal Requirements:</strong> We may disclose your information if
        required by law or to protect our rights or the rights of others.
      </p>

      <h2>4. Data Security</h2>
      <p>
        We use appropriate security measures to protect your personal
        information from unauthorized access, disclosure, alteration, or
        destruction. However, no method of transmission over the internet is
        100% secure, and we cannot guarantee absolute security.
      </p>

      <h2>5. Your Rights and Choices</h2>
      <p>
        <strong>Access and Correction:</strong> You have the right to access and
        correct your personal information. If you want to make changes or delete
        your data, please contact us at [Your Contact Information].
      </p>
      <p>
        <strong>Opt-out:</strong> If you wish to stop receiving promotional
        emails, you can unsubscribe by following the instructions in any email
        you receive from us.
      </p>

      <h2>6. Third-Party Links</h2>
      <p>
        Our website or app may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these websites.
        Please review their privacy policies before providing any personal
        information.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated effective date. Please review it
        periodically for any updates.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p>Email: kjksgoodhealth@gmail.com</p>
      <p>Phone: +91 9732604409</p>
      <p>Address: Sarada, Contai, Purba Medinipur</p>
    </div>
  );
};

export default PrivacyPolicy;
