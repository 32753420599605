import { call, put, takeLatest } from "redux-saga/effects";
import {
  checkempty,
  commonApiService,
  getHttpHeaders,
  handleErrorBlock,
} from "../utils/Helpers";
import {
  ADD_ASSETS,
  ADD_ASSETS_CATEGORY,
  ASSET_BILLING_ROUTE,
  ASSET_HISTORY_ROUTE,
  ERROR,
  FAILED_FOR_ASSET_BILLING,
  FAILED_GET_ASSET_TRANSACTION_HISTORY,
  FAILED_MASTER_LIST,
  FAILED_TO_ADD_ASSETS,
  FAILED_TO_ADD_ASSETS_CATEGORY,
  FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA,
  FAILED_TO_ADD_VILLAGE_SURVEY_DATA,
  FAILED_TO_CHECK_ASSET_AVAILABILITY,
  FAILED_TO_GET_ASSETS,
  FAILED_TO_GET_ASSETS_CATEGORY,
  FAILED_TO_GET_ASSETS_CATEGORY_WITH_ID,
  FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA,
  FAILED_TO_GET_VILLAGE_SURVEY_DATA,
  GET,
  GET_AN_ASSET_CATEGORY_ROUTE,
  GET_ASSET_AVAILABILITY,
  GET_ASSETS,
  GET_ASSETS_CATEGORY,
  GET_HOUSEHOLD_SURVEY_DATA,
  GET_VILLAGE_SURVEY_DATA,
  HOUSEHOLD_SURVEY_DATA_ADD,
  MASTER_ROUTE,
  POST,
  REQUEST_FOR_ASSET_BILLING,
  REQUEST_GET_ASSET_TRANSACTION_HISTORY,
  REQUEST_MASTER_LIST,
  REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA,
  REQUEST_TO_ADD_VILLAGE_SURVEY_DATA,
  REQUEST_TO_CHECK_ASSET_AVAILABILITY,
  REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA,
  REQUEST_TO_GET_VILLAGE_SURVEY_DATA,
  REQUSET_TO_ADD_ASSETS,
  REQUSET_TO_ADD_ASSETS_CATEGORY,
  REQUSET_TO_GET_ASSETS,
  REQUSET_TO_GET_ASSETS_CATEGORY,
  REQUSET_TO_GET_ASSETS_CATEGORY_WITH_ID,
  SUCCESS,
  SUCCESS_FOR_ASSET_BILLING,
  SUCCESS_GET_ASSET_TRANSACTION_HISTORY,
  SUCCESS_MASTER_LIST,
  SUCCESS_TO_ADD_ASSETS,
  SUCCESS_TO_ADD_ASSETS_CATEGORY,
  SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA,
  SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA,
  SUCCESS_TO_CHECK_ASSET_AVAILABILITY,
  SUCCESS_TO_GET_ASSETS,
  SUCCESS_TO_GET_ASSETS_CATEGORY,
  SUCCESS_TO_GET_ASSETS_CATEGORY_WITH_ID,
  SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA,
  SUCCESS_TO_GET_VILLAGE_SURVEY_DATA,
  VILLAGE_SURVEY_DATA_ADD,
  WARNING,
  REQUEST_TO_GET_ASSET_DETAILS_WITH_ID,
  SUCCESS_TO_GET_ASSET_DETAILS_WITH_ID,
  FAILED_TO_GET_ASSET_DETAILS_WITH_ID,
  GET_ASSET_DETAILS_WITH_ID,
  REQUEST_TO_UPDATE_AN_ASSET,
  SUCCESS_TO_UPDATE_AN_ASSET,
  FAILED_TO_UPDATE_AN_ASSET,
  UPDATE_ASSET_DETAILS_WITH_ID,
  PUT,
} from "../utils/Constants";
import { displayAlert } from "../utils/AlertHelper";

export function* GetAllMasterData(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${MASTER_ROUTE}${action.payload}`,
      headers
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_MASTER_LIST,
          response: apiResponse?.data,
          payload: action.payload,
        });
      }
    }
  } catch (error) {
    handleErrorBlock(error);
    yield put({
      type: FAILED_MASTER_LIST,
      error: error,
    });
  }
}
export function* watchGetAllMasterData() {
  yield takeLatest(REQUEST_MASTER_LIST, GetAllMasterData);
}
export function* RequestToAddSurveyDataForHousehold(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      POST,
      `${HOUSEHOLD_SURVEY_DATA_ADD}`,
      headers,
      action?.payload
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
        yield put({
          type: FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA,
          error: {
            message: !!apiResponse?.data?.message
              ? apiResponse?.data?.message
              : "Something went wrong",
          },
        });
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA,
      error: error,
    });
  }
}
export function* WatchRequestToAddSurveyDataForHousehold() {
  yield takeLatest(
    REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA,
    RequestToAddSurveyDataForHousehold
  );
}
export function* GetAllHouseholdSurveyData(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${GET_HOUSEHOLD_SURVEY_DATA}/${action?.payload}`,
      headers
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
        yield put({
          type: FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA,
          error: {
            message: !!apiResponse?.data?.message
              ? apiResponse?.data?.message
              : "Something went wrong",
          },
        });
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA,
      error: error,
    });
  }
}
export function* WatchGetAllHouseholdSurveyData() {
  yield takeLatest(
    REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA,
    GetAllHouseholdSurveyData
  );
}
export function* RequestToAddSurveyDataForVillage(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      POST,
      `${VILLAGE_SURVEY_DATA_ADD}`,
      headers,
      action?.payload
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
        yield put({
          type: FAILED_TO_ADD_VILLAGE_SURVEY_DATA,
          error: {
            message: !!apiResponse?.data?.message
              ? apiResponse?.data?.message
              : "Something went wrong",
          },
        });
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: FAILED_TO_ADD_VILLAGE_SURVEY_DATA,
      error: error,
    });
  }
}
export function* WatchRequestToAddSurveyDataForVillage() {
  yield takeLatest(
    REQUEST_TO_ADD_VILLAGE_SURVEY_DATA,
    RequestToAddSurveyDataForVillage
  );
}
export function* GetAllVillageSurveyData(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${GET_VILLAGE_SURVEY_DATA}/${action?.payload}`,
      headers
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
        yield put({
          type: FAILED_TO_GET_VILLAGE_SURVEY_DATA,
          error: {
            message: !!apiResponse?.data?.message
              ? apiResponse?.data?.message
              : "Something went wrong",
          },
        });
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_GET_VILLAGE_SURVEY_DATA,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: FAILED_TO_GET_VILLAGE_SURVEY_DATA,
      error: error,
    });
  }
}
export function* WatchGetAllVillageSurveyData() {
  yield takeLatest(REQUEST_TO_GET_VILLAGE_SURVEY_DATA, GetAllVillageSurveyData);
}
export function* AddAssetCategory(action) {
  try {
    let API_URL = "";
    let requestBody = null;
    if (!checkempty(action?.payload?.categoryId)) {
      API_URL = `${ADD_ASSETS_CATEGORY}`;
      requestBody = action?.payload;
    } else {
      API_URL = `${ADD_ASSETS_CATEGORY}/${action?.payload?.categoryId}`;
      requestBody = {
        categoryName: action?.payload?.categoryName,
      };
    }
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      POST,
      `${API_URL}`,
      headers,
      requestBody
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_ADD_ASSETS_CATEGORY,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    handleErrorBlock(error);
    yield put({
      type: FAILED_TO_ADD_ASSETS_CATEGORY,
      error: error,
    });
  }
}
export function* WatchAddAssetCategory() {
  yield takeLatest(REQUSET_TO_ADD_ASSETS_CATEGORY, AddAssetCategory);
}
// saga generator function to get all assets categories
export function* GetAllAssetsCategories() {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${GET_ASSETS_CATEGORY}`,
      headers
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_GET_ASSETS_CATEGORY,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: FAILED_TO_GET_ASSETS_CATEGORY,
      error: error,
    });
  }
}
export function* WatchGetAllAssetsCategories() {
  yield takeLatest(REQUSET_TO_GET_ASSETS_CATEGORY, GetAllAssetsCategories);
}
// saga generator function to add asset
export function* AddAssets(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      POST,
      `${ADD_ASSETS}`,
      headers,
      action?.payload
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_ADD_ASSETS,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    yield put({
      type: FAILED_TO_ADD_ASSETS,
      error: error,
    });
  }
}
export function* WatchAddAssets() {
  yield takeLatest(REQUSET_TO_ADD_ASSETS, AddAssets);
}
// saga generator function to get all assets
export function* GetAllAssets(action) {
  try {
    let API_URL = `${GET_ASSETS}`;
    // check category id is present or not
    if (action?.payload?.categoryId) {
      API_URL = `${GET_ASSETS}/${action?.payload?.categoryId}`;
    }
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${API_URL}`,
      headers
    );
    // console.log(apiResponse?.status);
    if (apiResponse?.status === 200) {
      if (apiResponse?.data?.status === false) {
        displayAlert(ERROR, apiResponse?.data?.message);
      } else {
        // console.log(apiResponse?.data?.message);
        displayAlert(SUCCESS, apiResponse?.data?.message);
        yield put({
          type: SUCCESS_TO_GET_ASSETS,
          response: apiResponse?.data,
        });
      }
    }
  } catch (error) {
    console.log(error);

    yield put({
      type: FAILED_TO_GET_ASSETS,
      error: error,
    });
  }
}
export function* WatchGetAllAssets() {
  yield takeLatest(REQUSET_TO_GET_ASSETS, GetAllAssets);
}
export function* CheckAssetAvailabilityWithId(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${GET_ASSET_AVAILABILITY}/${action?.payload?.id}`,
      headers
    );
    yield put({
      type: SUCCESS_TO_CHECK_ASSET_AVAILABILITY,
      response: apiResponse,
    });
  } catch (error) {
    yield put({
      type: FAILED_TO_CHECK_ASSET_AVAILABILITY,
      error: error,
    });
  }
}
export function* WatchCheckAssetAvailabilityWithId() {
  yield takeLatest(
    REQUEST_TO_CHECK_ASSET_AVAILABILITY,
    CheckAssetAvailabilityWithId
  );
}
export function* BillAnAsset(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      POST,
      `${ASSET_BILLING_ROUTE}`,
      headers,
      action?.payload
    );
    displayAlert(
      apiResponse?.status === 201 ? SUCCESS : WARNING,
      apiResponse?.data?.message
    );
    yield put({
      type: SUCCESS_FOR_ASSET_BILLING,
      response: apiResponse,
    });
  } catch (error) {
    yield put({
      type: FAILED_FOR_ASSET_BILLING,
      error: error,
    });
  }
}
export function* WatchBillAnAsset() {
  yield takeLatest(REQUEST_FOR_ASSET_BILLING, BillAnAsset);
}
export function* RequestToGetAssetHistory(action) {
  try {
    let API_URL = `${ASSET_HISTORY_ROUTE}`;
    if (checkempty(action?.payload)) {
      API_URL = `${ASSET_HISTORY_ROUTE}/${action?.payload}`;
    }
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${API_URL}`,
      headers
    );
    displayAlert(SUCCESS, apiResponse?.data?.message);
    yield put({
      type: SUCCESS_GET_ASSET_TRANSACTION_HISTORY,
      response: apiResponse?.data,
    });
  } catch (error) {
    displayAlert(ERROR, error?.message);
    yield put({
      type: FAILED_GET_ASSET_TRANSACTION_HISTORY,
      error: error,
    });
  }
}
export function* WatchRequestToGetAssetHistory() {
  yield takeLatest(
    REQUEST_GET_ASSET_TRANSACTION_HISTORY,
    RequestToGetAssetHistory
  );
}
export function* GetAssetCategoryDetailsWithId(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${GET_AN_ASSET_CATEGORY_ROUTE}${action?.payload}`,
      headers
    );
    displayAlert(SUCCESS, apiResponse?.data?.message);
    yield put({
      type: SUCCESS_TO_GET_ASSETS_CATEGORY_WITH_ID,
      response: apiResponse?.data,
    });
  } catch (error) {
    yield put({
      type: FAILED_TO_GET_ASSETS_CATEGORY_WITH_ID,
      error: error,
    });
  }
}
export function* WatchGetAssetCategoryDetailsWithId() {
  yield takeLatest(
    REQUSET_TO_GET_ASSETS_CATEGORY_WITH_ID,
    GetAssetCategoryDetailsWithId
  );
}
export function* GetAssetDetailsWithId(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      GET,
      `${GET_ASSET_DETAILS_WITH_ID}/${action?.payload}`,
      headers
    );
    displayAlert(SUCCESS, apiResponse?.data?.message);
    // console.log(apiResponse?.data?.assetDetails);
    yield put({
      type: SUCCESS_TO_GET_ASSET_DETAILS_WITH_ID,
      response: apiResponse?.data?.assetDetails,
    });
  } catch (error) {
    yield put({
      type: FAILED_TO_GET_ASSET_DETAILS_WITH_ID,
      error: error,
    });
  }
}
export function* WatchGetAssetDetailsWithId() {
  yield takeLatest(REQUEST_TO_GET_ASSET_DETAILS_WITH_ID, GetAssetDetailsWithId);
}
export function* UpdateAssetDetailsWithId(action) {
  try {
    const headers = getHttpHeaders("withAuth");
    const apiResponse = yield call(
      commonApiService,
      PUT,
      `${UPDATE_ASSET_DETAILS_WITH_ID}/${action?.payload?.assetId}`,
      headers,
      action?.payload
    );
    displayAlert(SUCCESS, apiResponse?.data?.message);
    yield put({
      type: SUCCESS_TO_UPDATE_AN_ASSET,
      response: apiResponse?.data,
    });
  } catch (error) {
    yield put({
      type: FAILED_TO_UPDATE_AN_ASSET,
      error: error,
    });
  }
}
export function* WatchUpdateAssetDetailsWithId() {
  yield takeLatest(REQUEST_TO_UPDATE_AN_ASSET, UpdateAssetDetailsWithId);
}
