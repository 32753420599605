/*import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import CustomSnackbar from '../sharedComponent/CustomToastAlert';

const AlertHelper = ({ message, type, duration }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [message, type, duration]);

    return (
        <CustomSnackbar message={message} type={type} duration={duration} visible={visible} />
    );
};

const alertContainer = document.createElement('div');
document.body.appendChild(alertContainer);
const root = createRoot(alertContainer);

export const displayAlert = (type = 'info', message, duration = 1500) => {
    root.render(<AlertHelper message={message} type={type} duration={duration} />);
};*/

import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import CustomSnackbar from '../sharedComponent/CustomToastAlert';

const AlertHelper = ({ message, type, duration, onClose }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setVisible(true);
            const timer = setTimeout(() => {
                setVisible(false);
                if (onClose) {
                    onClose();
                }
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [message, type, duration, onClose]);

    return (
        <CustomSnackbar message={message} type={type} duration={duration} visible={visible} />
    );
};

const alertContainer = document.createElement('div');
document.body.appendChild(alertContainer);
const root = createRoot(alertContainer);

export const displayAlert = (type = 'info', message, duration = 1500) => {
    const AlertWrapper = () => {
        const [showAlert, setShowAlert] = useState(true);

        const handleClose = () => {
            setShowAlert(false);
        };

        return showAlert ? (
            <AlertHelper message={message} type={type} duration={duration} onClose={handleClose} />
        ) : null;
    };

    root.render(<AlertWrapper />);
};