import { lazy, Suspense, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoutes from "./ProtectedRoutes";
import Loader from "../sharedComponent/Loader";
import { useSelector } from "react-redux";
import PrintPrescription from "../screens/patients/PrintPrescription";
import PrivacyPolicy from "../screens/content/PrivacyPolicy";

const LoginPage = lazy(() => import("../screens/Login"));
const Register = lazy(() => import("../screens/Register"));
const NoFoundComponent = lazy(() => import("../screens/Error404"));

const RootNavigation = (props) => {
  const AuthenticationReducer = useSelector(
    (state) => state.AuthenticationReducer
  );
  const isAuthenticated = AuthenticationReducer.isLoggedin;
  const urlParams = new URLSearchParams(window.location.search);
  const [urlParameter] = useState(urlParams.get("platform"));

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
            <LoginPage />
          </PublicRoute>
          <PublicRoute path="/register" isAuthenticated={isAuthenticated}>
            <Register />
          </PublicRoute>
          <PublicRoute
            path="/print/prescription/:id"
            isAuthenticated={isAuthenticated}
          >
            <PrintPrescription />
          </PublicRoute>
          <PublicRoute path="/privacy-policy" isAuthenticated={isAuthenticated}>
            <PrivacyPolicy />
          </PublicRoute>
          <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
            <ProtectedRoutes urlParameter={urlParameter} />
          </PrivateRoute>
          <Route path="*">
            <NoFoundComponent />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default RootNavigation;
