import { call, put, takeLatest } from 'redux-saga/effects';
import { commonApiService, getHttpHeaders, handleErrorBlock } from '../utils/Helpers';
import { ERROR, ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE, FAILED_ADD_FAMILY_MEMBERS, FAILED_FAMILY_MEMBERS, FAILED_TO_UPDATE_MEMBER_PHOTO, GET, HOUSEHOLD_ROUTE, MEMBERS_ADD_UPDATE, MEMBERS_DATA_ROUTE, MEMBERS_MODIFICATION, MEMBER_PHOTO_UPDATE, POST, PUT, REQUEST_ADD_FAMILY_MEMBERS, REQUEST_FAMILY_MEMBERS, REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE, REQUEST_TO_UPDATE_MEMBER_PHOTO, SUCCESS, SUCCESS_ADD_FAMILY_MEMBERS, SUCCESS_FAMILY_MEMBERS, SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE, SUCCESS_TO_UPDATE_MEMBER_PHOTO } from '../utils/Constants';
import { displayAlert } from '../utils/AlertHelper';

export function* GetFamilyMembersData(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, `${MEMBERS_DATA_ROUTE}${action.payload}`, headers);
        // console.log(apiResponse?.data);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message);
            } else {
                // console.log(apiResponse?.data?.message);
                displayAlert(SUCCESS, apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_FAMILY_MEMBERS,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        handleErrorBlock(error);
        yield put({
            type: FAILED_FAMILY_MEMBERS,
            error: error
        });
    }
}
export function* watchGetFamilyMembersData() {
    yield takeLatest(REQUEST_FAMILY_MEMBERS, GetFamilyMembersData);
}
export function* PerformAddEditMemberData(action) {
    try {
        const headers = getHttpHeaders("withAuth");
        let apiResponse = null;
        if (action?.payload?.id === "") {
            apiResponse = yield call(commonApiService, POST, MEMBERS_ADD_UPDATE, headers, action.payload);
        } else if (action?.payload?.id !== "") {
            apiResponse = yield call(commonApiService, PUT, MEMBERS_MODIFICATION, headers, action.payload);
        }
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(apiResponse?.data?.message, ERROR);
            } else {
                // console.log(apiResponse?.data);
                displayAlert(apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_ADD_FAMILY_MEMBERS,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        // console.log(error);
        handleErrorBlock(error);
        yield put({
            type: FAILED_ADD_FAMILY_MEMBERS,
            error: error
        });
    }
}
export function* watchPerformAddEditMemberData() {
    yield takeLatest(REQUEST_ADD_FAMILY_MEMBERS, PerformAddEditMemberData);
}
export function* PerformUploadMemberPhoto(action) {
    try {
        const headers = getHttpHeaders("withAuth");
        let apiResponse = yield call(commonApiService, PUT, MEMBER_PHOTO_UPDATE, headers, action.payload);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(apiResponse?.data?.message, ERROR);
            } else {
                // console.log(apiResponse?.data);
                // displayAlert(apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_TO_UPDATE_MEMBER_PHOTO,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        // console.log(error);
        handleErrorBlock(error);
        yield put({
            type: FAILED_TO_UPDATE_MEMBER_PHOTO,
            error: error
        });
    }
}
export function* watchPerformUploadMemberPhoto() {
    yield takeLatest(REQUEST_TO_UPDATE_MEMBER_PHOTO, PerformUploadMemberPhoto);
}
export function* GetHouseHoldsDropdownData(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, `${HOUSEHOLD_ROUTE}/${action?.payload?.villageId}`, headers);
        yield put({
            type: SUCCESS_TO_GET_HOUSEHOLD_VILLAGE_WISE,
            response: apiResponse?.data
        });
    } catch (error) {
        yield put({
            type: ERROR_TO_GET_HOUSEHOLD_VILLAGE_WISE,
            error: error
        })
    }
}
export function* WatchGetHouseHoldsDropdownData() {
    yield takeLatest(REQUEST_TO_GET_HOUSEHOLD_VILLAGE_WISE, GetHouseHoldsDropdownData);
}