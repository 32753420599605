import {
  FAILED_FOR_ASSET_BILLING,
  FAILED_GET_ASSET_TRANSACTION_HISTORY,
  FAILED_TO_ADD_ASSETS,
  FAILED_TO_ADD_ASSETS_CATEGORY,
  FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA,
  FAILED_TO_ADD_VILLAGE_SURVEY_DATA,
  FAILED_TO_CHECK_ASSET_AVAILABILITY,
  FAILED_TO_GET_ASSETS,
  FAILED_TO_GET_ASSETS_CATEGORY,
  FAILED_TO_GET_ASSETS_CATEGORY_WITH_ID,
  FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA,
  FAILED_TO_GET_VILLAGE_SURVEY_DATA,
  REQUEST_FOR_ASSET_BILLING,
  REQUEST_GET_ASSET_TRANSACTION_HISTORY,
  REQUEST_MASTER_LIST,
  REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA,
  REQUEST_TO_ADD_VILLAGE_SURVEY_DATA,
  REQUEST_TO_CHECK_ASSET_AVAILABILITY,
  REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA,
  REQUEST_TO_GET_VILLAGE_SURVEY_DATA,
  REQUSET_TO_ADD_ASSETS,
  REQUSET_TO_ADD_ASSETS_CATEGORY,
  REQUSET_TO_GET_ASSETS,
  REQUSET_TO_GET_ASSETS_CATEGORY,
  REQUSET_TO_GET_ASSETS_CATEGORY_WITH_ID,
  SUCCESS_FOR_ASSET_BILLING,
  SUCCESS_GET_ASSET_TRANSACTION_HISTORY,
  SUCCESS_MASTER_LIST,
  SUCCESS_TO_ADD_ASSETS,
  SUCCESS_TO_ADD_ASSETS_CATEGORY,
  SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA,
  SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA,
  SUCCESS_TO_CHECK_ASSET_AVAILABILITY,
  SUCCESS_TO_GET_ASSETS,
  SUCCESS_TO_GET_ASSETS_CATEGORY,
  SUCCESS_TO_GET_ASSETS_CATEGORY_WITH_ID,
  SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA,
  SUCCESS_TO_GET_VILLAGE_SURVEY_DATA,
  REQUEST_TO_GET_ASSET_DETAILS_WITH_ID,
  SUCCESS_TO_GET_ASSET_DETAILS_WITH_ID,
  FAILED_TO_GET_ASSET_DETAILS_WITH_ID,
  REQUEST_TO_UPDATE_AN_ASSET,
  SUCCESS_TO_UPDATE_AN_ASSET,
  FAILED_TO_UPDATE_AN_ASSET,
} from "../../utils/Constants";

const initialState = {
  status: "",
  isActivityLoader: false,
  panchayet: null,
  village: null,
  household: null,
  householdSurveyDataActivityLoader: false,
  householdSurveyDataAddResponse: null,
  householdSurveyData: [],
  householdSurveyDataGetActivityLoader: false,
  villageSurveyDataActivityLoader: false,
  villageSurveyDataAddResponse: null,
  villageSurveyData: [],
  villageSurveyDataGetActivityLoader: false,
  allAssets: [],
  allAssetsCategories: [],
  assetActivityLoader: false,
  assetAvailability: null,
  assetBillingResponse: null,
  assetTransactionHistory: [],
  assetDetialsWithId: null,
  assetAllDetailsWithId: null,
  error: null,
};

const MasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TO_UPDATE_AN_ASSET:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_UPDATE_AN_ASSET:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
      };
    case FAILED_TO_UPDATE_AN_ASSET:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUEST_TO_GET_ASSET_DETAILS_WITH_ID:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_GET_ASSET_DETAILS_WITH_ID:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        assetAllDetailsWithId: action?.response,
      };
    case FAILED_TO_GET_ASSET_DETAILS_WITH_ID:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUSET_TO_GET_ASSETS_CATEGORY_WITH_ID:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_GET_ASSETS_CATEGORY_WITH_ID:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        assetDetialsWithId: action?.response?.data,
      };
    case FAILED_TO_GET_ASSETS_CATEGORY_WITH_ID:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUEST_GET_ASSET_TRANSACTION_HISTORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_GET_ASSET_TRANSACTION_HISTORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        assetTransactionHistory: action.response?.data,
      };
    case FAILED_GET_ASSET_TRANSACTION_HISTORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action.error,
      };
    case REQUEST_FOR_ASSET_BILLING:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_FOR_ASSET_BILLING:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        assetBillingResponse: action?.response,
      };
    case FAILED_FOR_ASSET_BILLING:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
      };
    case REQUEST_TO_CHECK_ASSET_AVAILABILITY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_CHECK_ASSET_AVAILABILITY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        assetAvailability: action?.response?.data,
      };
    case FAILED_TO_CHECK_ASSET_AVAILABILITY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        assetAvailability: null,
        error: action?.error,
      };
    case REQUSET_TO_ADD_ASSETS_CATEGORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_ADD_ASSETS_CATEGORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        allAssetsCategories: action?.response?.data,
      };
    case FAILED_TO_ADD_ASSETS_CATEGORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUSET_TO_GET_ASSETS_CATEGORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_GET_ASSETS_CATEGORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        allAssetsCategories: action?.response?.data,
      };
    case FAILED_TO_GET_ASSETS_CATEGORY:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUSET_TO_ADD_ASSETS:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_ADD_ASSETS:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
      };
    case FAILED_TO_ADD_ASSETS:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUSET_TO_GET_ASSETS:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: true,
      };
    case SUCCESS_TO_GET_ASSETS:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        allAssets: action?.response?.data,
      };
    case FAILED_TO_GET_ASSETS:
      return {
        ...state,
        status: action.type,
        assetActivityLoader: false,
        error: action?.error,
      };
    case REQUEST_MASTER_LIST:
      return {
        ...state,
        status: action.type,
        isActivityLoader: true,
      };
    case SUCCESS_MASTER_LIST:
      if (action.payload === "panchayet") {
        return {
          ...state,
          status: action.type,
          isActivityLoader: false,
          panchayet: action.response,
        };
      } else if (action.payload === "village") {
        return {
          ...state,
          status: action.type,
          isActivityLoader: false,
          village: action.response,
        };
      } else if (action.payload === "household") {
        return {
          ...state,
          status: action.type,
          isActivityLoader: false,
          household: action.response,
        };
      }
      break;
    case REQUEST_TO_ADD_HOUSEHOLD_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        householdSurveyDataActivityLoader: true,
      };
    case SUCCESS_TO_ADD_HOUSEHOLD_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        householdSurveyDataAddResponse: action?.response,
        householdSurveyDataActivityLoader: false,
      };
    case FAILED_TO_ADD_HOUSEHOLD_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        householdSurveyDataActivityLoader: false,
        error: action?.error,
      };
    case REQUEST_TO_GET_HOUSEHOLD_SURVEY_DATA:
      return {
        ...state,
        householdSurveyDataGetActivityLoader: true,
        status: action.type,
      };
    case SUCCESS_TO_GET_HOUSEHOLD_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        householdSurveyDataGetActivityLoader: false,
        householdSurveyData: action?.response,
      };
    case FAILED_TO_GET_HOUSEHOLD_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        householdSurveyDataGetActivityLoader: false,
        error: action?.error,
      };
    case REQUEST_TO_ADD_VILLAGE_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        villageSurveyDataActivityLoader: true,
      };
    case SUCCESS_TO_ADD_VILLAGE_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        villageSurveyDataAddResponse: action?.response,
        villageSurveyDataActivityLoader: false,
      };
    case FAILED_TO_ADD_VILLAGE_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        villageSurveyDataActivityLoader: false,
        error: action?.error,
      };
    case REQUEST_TO_GET_VILLAGE_SURVEY_DATA:
      return {
        ...state,
        villageSurveyDataGetActivityLoader: true,
        status: action.type,
      };
    case SUCCESS_TO_GET_VILLAGE_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        villageSurveyDataGetActivityLoader: false,
        villageSurveyData: action?.response,
      };
    case FAILED_TO_GET_VILLAGE_SURVEY_DATA:
      return {
        ...state,
        status: action.type,
        villageSurveyDataGetActivityLoader: false,
        error: action?.error,
      };
    default:
      return state;
  }
};

export default MasterReducer;
