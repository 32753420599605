import { all } from "redux-saga/effects";
import { watchGetLoginUserData, watchPerformLogin } from "./AuthenticationSaga";
import {
  WatchAddAssetCategory,
  WatchAddAssets,
  WatchBillAnAsset,
  WatchCheckAssetAvailabilityWithId,
  WatchGetAllAssets,
  WatchGetAllAssetsCategories,
  WatchGetAllHouseholdSurveyData,
  WatchGetAllVillageSurveyData,
  WatchGetAssetCategoryDetailsWithId,
  WatchGetAssetDetailsWithId,
  WatchRequestToAddSurveyDataForHousehold,
  WatchRequestToAddSurveyDataForVillage,
  WatchRequestToGetAssetHistory,
  WatchUpdateAssetDetailsWithId,
  watchGetAllMasterData,
} from "./MasterSaga";
import {
  WatchGetHouseHoldsDropdownData,
  watchGetFamilyMembersData,
  watchPerformAddEditMemberData,
  watchPerformUploadMemberPhoto,
} from "./FamilyMemberSaga";
import { watchGetAllDashboardDataSaga } from "./DashboardSaga";

function* RootSaga() {
  yield all([
    watchPerformLogin(),
    watchGetLoginUserData(),
    watchGetAllMasterData(),
    watchGetFamilyMembersData(),
    watchPerformAddEditMemberData(),
    watchGetAllDashboardDataSaga(),
    watchPerformUploadMemberPhoto(),
    WatchGetHouseHoldsDropdownData(),
    WatchRequestToAddSurveyDataForHousehold(),
    WatchGetAllHouseholdSurveyData(),
    WatchRequestToAddSurveyDataForVillage(),
    WatchGetAllVillageSurveyData(),
    WatchAddAssetCategory(),
    WatchGetAllAssetsCategories(),
    WatchAddAssets(),
    WatchGetAllAssets(),
    WatchCheckAssetAvailabilityWithId(),
    WatchBillAnAsset(),
    WatchRequestToGetAssetHistory(),
    WatchGetAssetCategoryDetailsWithId(),
    WatchGetAssetDetailsWithId(),
    WatchUpdateAssetDetailsWithId(),
  ]);
}

export default RootSaga;
