import { call, put, takeLatest } from 'redux-saga/effects';
import { ERROR, FAILED_LOGIN_USER_DETAILS, FAILED_USER_LOGIN, GET, JWT_AUTH_TOKEN, LOGIN_ROUTE, LOGIN_USER_DETAILS_ROUTE, POST, REQUEST_LOGIN_USER_DETAILS, REQUEST_USER_LOGIN, SUCCESS_LOGIN_USER_DETAILS, SUCCESS_USER_LOGIN } from '../utils/Constants';
import { commonApiService, getHttpHeaders, handleErrorBlock } from '../utils/Helpers';
import { displayAlert } from '../utils/AlertHelper';

export function* PerformLogin(action) {
    try {
        const headers = getHttpHeaders();
        const apiResponse = yield call(commonApiService, POST, LOGIN_ROUTE, headers, action.payload);
        // console.log(apiResponse);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(ERROR, apiResponse?.data?.message, ERROR);
            } else {
                displayAlert(apiResponse?.data?.message);
                localStorage.setItem(JWT_AUTH_TOKEN, apiResponse?.data?.token);
                yield put({
                    type: SUCCESS_USER_LOGIN,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        // console.log(error);
        handleErrorBlock(error);
        yield put({
            type: FAILED_USER_LOGIN,
            error: error
        });
    }
}

export function* watchPerformLogin() {
    yield takeLatest(REQUEST_USER_LOGIN, PerformLogin);
}

export function* GetLoginUserData(action) {
    try {
        const headers = getHttpHeaders('withAuth');
        const apiResponse = yield call(commonApiService, GET, LOGIN_USER_DETAILS_ROUTE, headers);
        // console.log(apiResponse);
        if (apiResponse?.status === 200) {
            if (apiResponse?.data?.status === false) {
                displayAlert(apiResponse?.data?.message, ERROR);
            } else {
                displayAlert(apiResponse?.data?.message);
                yield put({
                    type: SUCCESS_LOGIN_USER_DETAILS,
                    response: apiResponse?.data
                });
            }
        }
    } catch (error) {
        handleErrorBlock(error);
        yield put({
            type: FAILED_LOGIN_USER_DETAILS,
            error: error
        });
    }
}

export function* watchGetLoginUserData() {
    yield takeLatest(REQUEST_LOGIN_USER_DETAILS, GetLoginUserData);
}