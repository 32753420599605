import React, { useEffect, useState } from 'react';
import '../assets/css/CustomToastAlert.css';

const CustomSnackbar = ({ message, type = 'info', duration = 3000, visible }) => {
    const [show, setShow] = useState(visible);

    useEffect(() => {
        if (visible) {
            setShow(true);
            const timer = setTimeout(() => {
                setShow(false);
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [visible, duration]);

    return (
        <div className={`snackbar ${type} ${show ? 'show' : ''}`}>
            {message}
        </div>
    );
};

export default CustomSnackbar;